<template>
  <v-card flat max-width="547px" class="transparent">
    <v-card-title>
      <span class="primary--text"> CREATE ACCOUNT </span>
    </v-card-title>
    <v-card-text>
      <v-row>
         <v-col>
          <Github />
        </v-col>

        <v-col>
          <Facebook />
        </v-col>

      </v-row>
      <div class="mt-5 d-flex">
        <v-divider class="my-auto" ></v-divider>
        <span class="mx-5"> or </span>
        <v-divider class="my-auto"></v-divider>
      </div>

      <v-alert
      type="success"
      outlined
      v-if="textInfo"
      class="my-5"
      >
        {{ textInfo }} 
      </v-alert>
      <v-card flat class="transparent">

        <div>
          <label for=""> Email address</label>
          <v-text-field
            v-model="email"
            class="rounded-lg"
            outlined
            prepend-inner-icon="mdi-email"
            :rules="emailRules"
            :error-messages="handleErrors(register_errors['email'])"
          ></v-text-field>
        </div>

         <div>
          <label for="">Username</label>
          <v-text-field
            v-model="username"
            class="rounded-lg"
            outlined
            prepend-inner-icon="mdi-account"
            :rules="usernameRules"
            :error-messages="handleErrors(register_errors['username'])"
          ></v-text-field>
        </div>

        <div>
          <label for=""> password</label>
          <v-text-field
            v-model="password2"
            outlined
            class="rounded-lg"
            prepend-inner-icon="mdi-lock"
            :type="show ? 'text' : 'password'"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show = !show"
            :rules="passwordRules"
            :error-messages="handleErrors(register_errors['password2'])"
          ></v-text-field>
        </div>
        <div>
          <label for="">confirm  password</label>
          <v-text-field
              v-model="password1"
              outlined
              class="rounded-lg"
              prepend-inner-icon="mdi-lock"
              :type="show ? 'text' : 'password'"
              :rules="passwordRules"
              :error-messages="handleErrors(register_errors['password2'])"
          ></v-text-field>
        </div>

        <div class="d-flex mt-n3">
          <v-checkbox 
            v-model="checkbox"
            class=""
          >
          </v-checkbox>
          <div class="my-5">
            I agree to the Platform’s <router-link target="_blank" to="/terms&conditions">Terms of Service and Privacy Policy </router-link>
          </div>
        </div>

        <div class="">
          <v-btn :loading="loading" color="blue darken-4 rounded-lg" class="pa-7" dark block @click="handleRegister">Sign Up </v-btn>
          <div class="mt-2">
            <span >Already have an account? <router-link to="/auth/login"> Sign In </router-link> </span>
          </div>
        </div>
      </v-card>

    </v-card-text>
  </v-card>
</template>

<script>
import colorMxn from '@/mixins/color_mixin';
import formMxn from '@/mixins/form_mixin';
import { mapGetters } from "vuex";

export default {
  name: 'Register',
  components: {
    Facebook: () => import('./facebook'),
    Github: () => import('./github'),
  },
  mixins: [colorMxn, formMxn],
  data() {
    return {
      show: false,
      emailRules: [
        v => !!v || "Email is required",
        v => /.+@.+/.test(v) || "E-mail must be valid"
      ],
      usernameRules: [
        v => !!v || "Username is required",
      ],
      passwordRules: [v => !!v || "password is required"],
      email: '',
      username: '',
      password1: '',
      password2: '',
      checkbox: false,
      loading: false,
      textInfo: '',
    }
  },
  computed: {
    ...mapGetters(["register_errors", "register_success"]),
  },
  watch: {
    register_errors(old, val) {
      if (old !== val) {
        this.loading = false;
      }
      
    },
    register_success(old, val) {
       if (old !== val) {
        this.loading = false;
        this.textInfo = 'Account Created! Check your email inbox to verify account';
        // this.$router.push('/auth/login');
      }
    }
  },
  methods:{
    handleRegister(){
      this.loading = true;
      let data = {
        username:this.username,
        email:this.email,
        password1:this.password1,
        password2:this.password2,
      }
      this.$store.commit("register", data);
    },
  }
}
</script>